import { Layout, SEO, Title } from "../components"
import LightboxGallery, { photoMapper } from "../components/lightbox-gallery"

import { Grid } from "semantic-ui-react"
import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

const GalleryPage = ({ data }) => {
  const { edges } = data.allStrapiImage
  const photos = photoMapper(edges)

  return (
    <Layout>
      <Wrapper>
        <SEO title="Galerie" />
        <Title title="Galerie" />
        <Grid centered>
          <Grid.Column width={14}>
            <LightboxGallery photos={photos} />
          </Grid.Column>
        </Grid>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.main`
  background: var(--clr-grey-9);
  min-height: calc(100vh - 4rem);

  h2 {
    font-size: 2rem;
    color: var(--clr-grey-1);
    margin: 2rem 0;
  }
  @media (min-width: 800px) {
    h2 {
      font-size: 3rem;
    }
  }
`
export const query = graphql`
  query AllImagesQuery {
    allStrapiImage {
      edges {
        node {
          id
          title
          url {
            childImageSharp {
              fixed(width: 960) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`

export default GalleryPage
